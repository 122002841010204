import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Contact } from "./components/contact";
import { useLanguage } from "./context/language";
import JsonDataEnglish from "./data/data-english.json";
import JsonDataPortugues from "./data/data-portugues.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Controller from './components/controller';
import Main from "./components/main";
import ErrorPage from "./components/error-page";
import Blog from "./components/blog";
// import UspOneMillionDevices from "./components/articles/usp-1m-devices";
import OssDeviceManagement from "./components/articles/oss-device-management";
import WhatisOktopus from "./components/articles/what-is-oktopus";
import ScheduleMeeting from "./components/schedule-meeting";
import SEO from "./components/SEO";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 500,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState(null)
  const {language} = useLanguage();

  useEffect(() => {
    if (language === "pt"){
      setLandingPageData(JsonDataPortugues);
    }else {
      setLandingPageData(JsonDataEnglish);
    }

  }, [landingPageData, language]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <><SEO keywords={"IoT, TR-369, USP, CWMP, TR-069, Software, Open Source, Cloud, Consultancy, Development, Solutions"}  title={"Oktopus Solutions"} description={"Oktopus specializes in crafting IoT solutions using USP and CWMP protocols, alongside offering open-source solutions. With a keen focus on innovation, Oktopus delivers cutting-edge technology tailored to Telco, 5G and IoT industries. Committed to accessibility and collaboration, they empower clients with customizable, scalable solutions, driving efficiency and connectivity in the rapidly evolving digital landscape."}/><Main landingPageData={landingPageData}/></>,
      errorElement: <ErrorPage />,
    },
    {
      path: "controller",
      element: <><SEO keywords={"IoT, TR-369, USP, CWMP, TR-069, Software, Open Source, Cloud, Consultancy, Development, Solutions, Controller"}  title={"Oktopus Controller"} description={"Oktopus Controller to manage CPEs and IoT devices through USP and CWMP."}/><Controller data={landingPageData}/></>,
    },
    {
      path : "blog",
      element: <Blog/>,
    },
    {
      path : "schedule-meeting",
      element: <ScheduleMeeting/>,
    },
    {
      path: "blog/oss-device-management",
      element:<OssDeviceManagement/>
    },
    // {
    //   path: "blog/usp-1m-devices",
    //   element:<UspOneMillionDevices/>,
    // },
    {
      path: "blog/what-is-oktopus",
      element:<WhatisOktopus/>,
    },
  ]);

  return landingPageData &&
    <div>
      <Navigation data={landingPageData.Navigation}/>
      <RouterProvider router={router} />
      {/* <Testimonials data={landingPageData.Testimonials} /> */}
      {/* <Team data={landingPageData.Team} /> */}
      <Contact data={landingPageData.Contact} />
    </div>
};

export default App;
