import React from "react";
import { useLanguage } from "../context/language";
import SEO from "./SEO";

export default function Blog(props){

    const {language} = useLanguage();

    return (
        <section id="blog">
            <SEO title="Oktopus | Blog" description={"Oktopus blog with information about Oktopus products, services, and technical information about USP, CWMP, and CPEs/IoTs management."} keywords="Blog, Oktopus"/>
            <div className="container">
                <div className="row">
                    <div className="about-text">
                        <h2>Blog</h2>
                    </div>
                    { language === "en" ?
                    // <div className="blog-cards">
                    //     <div className="col-xs-12 col-md-4">
                    //         <div class="card">
                    //             <img class="card-img-top" src="/img/articles/oktopus-initial.png" alt="Card image cap" width={"100%"} />
                    //             <div class="card-body">
                    //                 <h5 class="card-title">Scalling USP to 1 million devices</h5>
                    //                 <p class="card-text">Find how Oktopus is capable to handle more than one million simulated USP devices simultaneously connected with our Open Source Controller.</p>
                    //                 <a href="/blog/usp-1m-devices" class="btn btn-primary">Read</a>
                    //             </div>
                    //         </div>
                    //     </div>
                        <div className="blog-cards">
                            <div className="col-xs-12 col-md-4">
                                <div class="card">
                                    <img class="card-img-top" src="/img/articles/oktopus-initial.png" alt="Card image cap" width={"100%"} />
                                    <div class="card-body">
                                        <h5 class="card-title">What is the Oktopus Controller?</h5>
                                        <p class="card-text">Understand what is this software, what it solves, who is it for, what it makes possible.</p>
                                        <a href="/blog/what-is-oktopus" class="btn btn-primary">Read</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-md-4">
                                <div class="card">
                                    <div style={{textAlign:"center"}}>
                                    <img class="card-img-top" src="/img/articles/oss.png" alt="Card image cap" width={"100%"} />
                                    </div>
                                    <div class="card-body">
                                        <h5 class="card-title"> E2E Open Source Device Management Ecosystem</h5>
                                        <p class="card-text"> Manage your devices from the Control Plane to the Edge with a 100% Open Source stack.</p>
                                        <a href="/blog/oss-device-management" class="btn btn-primary">Read</a>
                                    </div>
                                </div>
                            </div>
                        {/* <div className="col-xs-12 col-md-4">
                            <div class="card">
                                <img class="card-img-top" src="/img/tr069-diagram.png" alt="Card image cap" width={"100%"} />
                                <div class="card-body">
                                    <h5 class="card-title">1 million devices with TR-069</h5>
                                    <p class="card-text">Get to know how Oktopus CWMP solution is capable to handle more than one million simulated devices simultaneously being managed by our Open Source Controller.</p>
                                    <a href="#" class="btn btn-primary">Read</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-4">
                            <div class="card">
                                <img class="card-img-top" src="/img/no-image.png" alt="Card image cap" width={"100%"}/>
                                <div class="card-body">
                                    <h5 class="card-title">Card title</h5>
                                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" class="btn btn-primary">Read</a>
                                </div>
                            </div>
                        </div> */}
                    </div>:
                    <div className="blog-cards">
                        <div className="col-xs-12 col-md-4">
                            <div class="card">
                                <img class="card-img-top" src="/img/articles/oktopus-initial.png" alt="Card image cap" width={"100%"} />
                                <div class="card-body">
                                    <h5 class="card-title">O que é o Controlador Oktopus?</h5>
                                    <p class="card-text">Entenda o que é este software, o que ele resolve, qual seu público alvo, e o que possibilita.</p>
                                    <a href="/blog/what-is-oktopus" class="btn btn-primary">Ler</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-4">
                            <div class="card">
                                <img class="card-img-top" src="/img/articles/oss.png" alt="Card image cap" width={"100%"} />
                                <div class="card-body">
                                    <h5 class="card-title"> Ecossistema Open Source para Gerência de Dispositivos </h5>
                                    <p class="card-text"> Gerencie seus dispositivos do Control Plane até a ponta com uma stack 100% Open Source.</p>
                                    <a href="/blog/oss-device-management" class="btn btn-primary">Ler</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </section>
    )
}