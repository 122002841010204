import React from "react";
import { useLanguage } from "../../context/language";
import SEO from "../SEO";
import Leandro from "./leandro";

export default function OssDeviceManagement (props) {
    const {language} = useLanguage();
  return (
    <div id="blog">
        <SEO  title={"Oktopus | Open Source CPE Management"} description={"E2E Open Source Device Management Ecosystem with TR-369 (USP) and TR-069 (CWMP)"} keywords={"USP,TR-369,CWMP,TR-069,OpenSource"}/>
        <div className="container">
            <div className="row">
                { language === "en" ?
                <div className="blog-text">
                    <div className="about-text">
                        <h2>E2E Open Source Device Management Ecosystem</h2>
                    </div>
                    <p>To have only one network equipment supplier contributes to a poor ecosystem, with less innovation, increased risk and the potential for high-cost solutions. With this in mind, internet and service providers seek to use open protocols such as TR-069 (CWMP), TR-369 (USP), SNMP, among others, to remotely manage their CPEs, as this allows them to have a homogeneous experience of processes and technical support even with a fleet of devices from different manufacturers.</p>
                    <p>The problem is that in order to manage devices through open protocols, the CSP or ISP often opts for commercial solutions that, even though they rely on open communication standards, they still have a proprietary core, causing the provider to replace one problem with another. If before they were hostage to just one equipment supplier, now they depend on a single software supplier with proprietary implementations for equipment management, even if through open standards such as CWMP and USP.</p>
                    <p>In an attempt to solve the problem above, a few softwares were created, such as GenieACS, FreeACS, OpenUSP, and others that, despite being extensible and offering interesting integration possibilities, fail to offer a cohesive end-to-end solution that is easy to use and has advanced actions designed for each provider's use case.</p>
                    <p>This is how Oktopus came about, a solution that guarantees the management of your equipment via CWMP and USP with open source code from the Control Plane to the end device. Available in two versions: </p>
                    <p><b>Community</b>: suited for enthusiasts and companies looking to create their own system, and integrations on top of a solid Open Source base, in the “do it yourself” style.</p>
                    <p><b>Enterprise</b>: a complete, customizable solution, with advanced multi-tenancy functions, Wi-Fi, firmware update, reboot, geolocation, problem diagnosis and more; having been built as an extension of the open source core, but with SLA guarantee and commercial support.</p>
                    <div style={{textAlign:"center"}} >
                        {/* <a href="https://docs.oktopus.app.br/getting-started/features#comparision"> */}
                        <img src="/img/articles/features-table.png" style={{maxWidth:"100%"}}></img>
                        {/* </a> */}
                    </div>
                    <p>Contact us by email:  <a href="mailto:sales@oktopus.app.br">sales@oktopus.app.br</a>, or schedule a conversation: <a href="https://oktopus.app.br/schedule-meeting" rel="nofollow">https://oktopus.app.br/schedule-meeting</a>, and find out how we can help to boost your business.</p>
                    <h5>Written by:</h5>
                    <Leandro lang={language} />
                    <br />
                    <span>Date: 09/09/2024</span>
                </div>
                :
                <div className="blog-text">
                    <div className="about-text">
                        <h2>Ecossistema Open Source para Gerência de Dispositivos</h2>
                    </div>
                    <p>Ter apenas uma fornecedora de equipamentos de rede contribui para um ecossistema pobre, com menos inovação, aumento de risco e potencial para soluções de custos elevados. Com isso em vista, provedores de internet e serviços buscam utilizar protocolos abertos como o TR-069 (CWMP), TR-369 (USP), SNMP, dentre outros, para realizar o gerenciamento remoto de suas CPEs, pois isso os possibilita ter uma experiência homogênea de processos e suporte técnico mesmo tendo um parque com dispositivos de fabricantes diversos. </p>
                    <p>O problema é que frequentemente para gerenciar dispositivos por meio de protocolos abertos, o CSP ou ISP opta por soluções comerciais que mesmo que se apoiem em padrões de comunicação abertos, possuem o core proprietário, fazendo o provedor substituir um problema por outro. Se antes ele era refém de apenas uma fornecedora de equipamentos, agora ele se torna refém de uma única fornecedora de software com implementações proprietárias para gerência de equipamentos, ainda que por meio de padrões abertos como o CWMP e USP.</p>
                    <p>Buscando resolver o problema acima, foram criados alguns sistemas como o GenieACS, FreeACS, OpenUSP, e outros que apesar de serem extensíveis e proverem possibilidades interessantes de integração, pecam em oferecer uma solução coesa fim a fim, fácil de usar e com ações avançadas pensadas no caso de uso de cada provedor.</p>
                    <p>Foi assim que surgiu o Oktopus, uma solução que garante o gerenciamento de seus equipamentos via CWMP e USP com o código aberto desde o Control Plane até o dispositivo na ponta. Disponível em duas versões:</p>
                    <p><b>Community</b>: voltada para entusiastas e empresas que buscam criar seu próprio sistema, e integrações em cima de uma base sólida Open Source, no estilo “faça você mesmo”.</p>
                    <p><b>Enterprise</b>: uma solução completa, customizável, com funções avançadas de multitenancy, Wi-Fi, atualização de firmware, reboot, geolocalização, diagnóstico de problemas e mais; tendo sido construído como extensão do core de código aberto, mas com garantia de SLA e suporte comercial.</p>
                    <div style={{textAlign:"center"}}>
                        <a href="https://docs.oktopus.app.br/getting-started/features#comparision">
                        <img src="/img/articles/features-table.png"></img>
                        </a>
                    </div>
                    <p> Entre em contato conosco via email: <a href="mailto:vendas@oktopus.app.br">vendas@oktopus.app.br</a>, ou agende uma conversa: <a href="https://oktopus.app.br/schedule-meeting" rel="nofollow">https://oktopus.app.br/schedule-meeting</a>, e descubra como podemos ajudar a impulsionar o seu negócio.</p>
                    <h5>Escrito por:</h5>
                        <Leandro lang={language} />
                        <br />
                    <span>Data: 09/09/2024</span>
                </div>
                }
            </div>
        </div>
    </div>
  )
};
